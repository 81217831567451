@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('/fonts/NNNouvelleGrotesk-Light.woff2') format('woff2'),
    url('/fonts/NNNouvelleGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('/fonts/NNNouvelleGrotesk-Normal.woff2') format('woff2'),
    url('/fonts/NNNouvelleGrotesk-Normal.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NN Nouvelle Grotesk';
  src: url('/fonts/NNNouvelleGrotesk-Medium.woff2') format('woff2'),
    url('/fonts/NNNouvelleGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

#__next {
  height: 100%;
}

@layer utilities {
  .w-fit {
    width: fit-content;
  }

  .h-screen-minus-header {
    height: calc(100vh - 5rem);
  }

  .max-w-half-vw {
    max-width: 50vw;
  }

  .w-full-ignore-padding-4 {
    width: calc(100% + 2rem);
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .w-full-ignore-padding-14 {
    width: calc(100% + 7rem);
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .w-full-ignore-padding-16 {
    width: calc(100% + 8rem);
    margin-left: -4rem;
    margin-right: -4rem;
  }
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

/* Customize the label (the container) */
.custom-checkbox-container {
  display: block;
  position: relative;
  height: 25px;
  width: 25px;
  // padding-left: 35px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: white;
  border: 1px solid #323232;
  border-radius: 4px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox-container input:checked ~ .checkmark {
  background-color: #323232;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-disabled {
  cursor: not-allowed;
}

.checkbox-disabled:hover input ~ .checkmark {
  background-color: white;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

// This avoids the Safari mobile browser to auto zoom in on the input fields
@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
  select:active,
  textarea:active,
  input:active {
    font-size: 16px;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  // width: fit-content !important;
  // width: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noarrows input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.noarrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.noarrows input[type='number'] {
  -moz-appearance: textfield;
}

.animate-scrollImages {
  animation: scrollImages 20s linear infinite;
  overflow: hidden;
  height: 100%; /* Ensure the container takes up the full height */
}

@keyframes scrollImages {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* Looper styles */
@keyframes slideAnimVertical {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
}
@keyframes slideAnimHorizontal {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}
.looper[orientation='vertical'] {
  width: unset;
  height: 100%;
}

.looper__innerList {
  display: flex;
  justify-content: center;
  width: fit-content;
}
.looper__innerList[orientation='vertical'] {
  flex-direction: column;
}

.looper__innerList[data-animate='true'][orientation='vertical'] .looper__listInstance {
  animation: slideAnimVertical linear infinite;
}
.looper__innerList[data-animate='true'][orientation='horizontal'] .looper__listInstance {
  animation: slideAnimHorizontal linear infinite;
}

.looper__listInstance {
  display: flex;
  animation: none;
}
